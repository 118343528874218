<template>
  <a-sub-menu v-bind="$props" v-on="$listeners">
    <template slot="title">
      <ax-icon
        :type="subMenuInfo.meta.icon"
        :size="subMenuInfo.meta.iconSize || iconSize"
      />
      <span :title="subMenuInfo.meta.title">
        {{subMenuInfo.meta.title}}
      </span>
    </template>

    <template v-for="menuInfo in subMenuInfo.children">
      <template v-if="!menuInfo.meta.hideInMenu">
        <sub-menu
          v-if="hasChild(menuInfo)"
          :key="menuInfo.path"
          :sub-menu-info="menuInfo"
        />
        <a-menu-item
          v-else
          :key="menuItem(menuInfo).path"
        >
          <ax-icon
            :type="menuItem(menuInfo).meta.icon"
            :size="menuItem(menuInfo).meta.iconSize || iconSize"
          />
          <span :title="menuItem(menuInfo).meta.title">
            {{ menuItem(menuInfo).meta.title }}
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-sub-menu>
</template>

<script>
import { Menu } from 'ant-design-vue'
import mixin from './mixin'
export default {
  name: 'SubMenu',
  mixins: [mixin],
  props: {
    ...Menu.SubMenu.props,
    subMenuInfo: {
      type: Object,
      default: () => ({})
    },
    iconSize: {
      type: Number
    }
  }
}
</script>
