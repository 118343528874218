<template>
  <a-menu
    v-bind="$attrs"
    v-on="$listeners"
    :mode="mode"
    :selectedKeys="selectedKeys"
    :openKeys.sync="openKeys"
    @openChange="openChange"
    theme="dark"
  >
    <template v-for="menuInfo in menuList">
      <template v-if="!menuInfo.meta.hideInMenu">
        <sub-menu
          v-if="hasChild(menuInfo)"
          :key="menuInfo.path"
          :sub-menu-info="menuInfo"
          :icon-size="iconSize"
        />
        <a-menu-item v-else :key="menuItem(menuInfo).path">
          <ax-icon
            :type="menuItem(menuInfo).meta.icon"
            :size="menuItem(menuInfo).meta.iconSize || iconSize"
          />
          <span :title="menuItem(menuInfo).meta.title">
            {{ menuItem(menuInfo).meta.title }}
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import mixin from "./mixin";
import SubMenu from "./SubMenu";
export default {
  name: "AxMenu",
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      default: "inline",
    },
    defaultOpenKeys: {
      type: Array,
      default: () => ['/views/communityGovernance/basicOrganization/personnelManage/residentFile/index'],
    },
    iconSize: {
      type: Number,
    },
    menuList: {
      type: Array,
      default: () => [],
    },
  },
  components: { SubMenu },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
      cachedOpenKeys: [],
      rootSubmenuKeys:[]
    };
  },
  computed: {
    collapsed() {
      return this.$ax.config.collapsed;
    },
  },
  watch: {
    $route() {
      this.updateMenu();
    },
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys;
        this.openKeys = [];
      } else {
        this.openKeys = this.cachedOpenKeys;
      }
    },
  },
  created() {
    this.openKeys = []
    this.openKeys = this.defaultOpenKeys;
    this.updateMenu();
  },
  methods: {
    /**
     * 更新菜单
     */
    updateMenu() {
      const routes = this.$route.matched.concat();
      const openKeys = this.openKeys;
      // pop删除并返回数组最后一个元素（会改变原数组）
      const currentRoute = routes.pop();
      // 设置当前高亮的菜单
      this.selectedKeys = currentRoute.meta.activeMenu
        ? [currentRoute.meta.activeMenu]
        : [currentRoute.path];
      // 获取展开的菜单key并存起来
      routes.forEach((item) => {
        if (!openKeys.includes(item.path)) {
          openKeys.push(item.path);
        }
      });
      this.collapsed || this.mode === "horizontal"
        ? (this.cachedOpenKeys = openKeys)
        : (this.openKeys = openKeys);
    },
    // SubMenu 展开/关闭的回调
    openChange(openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
  },
};
</script>
<style>
</style>
